import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import marked from 'marked';

import CartContext from "../context/CartConext"
import s from "./styles.module.scss"
import Layout from '../components/layouts'

export const query = graphql`
query ProductQuery($uid: String!) {
  prismic {
    product(uid: $uid, lang: "en-ca"){
      _meta {
        type
        id
        uid
      }
      product_name
      product_image
      sub_title
      product_description
    }
  }
}
`


const RenderVariations = ({ variant, variants, setVariant }) => {
  const list = variants.sort((a, b) => b.variant.size > a.variant.size ? -1 : 1)
  return (
    <React.Fragment>
      {list.map((v, index) => (<button className={`btn btn-outline-secondary btn-sm mr-2 mb-2 rounded-pill px-3 ${v === variant ? "active" : ""}`} key={index} onClick={() => setVariant(v)}>{v.variant.size} - {v.variant.color}</button>))}
    </React.Fragment>
  )
}

const RenderBody = ({ product, defaultVariant, variants }) => {
  const [variant, setVariant] = React.useState(defaultVariant)
  const { addItem } = React.useContext(CartContext)

  return (
    <React.Fragment>
  
      <div className={s.productContainer}>

        <div className="text-center mb-5 mt-0">
          {RichText.render(product.product_name, linkResolver)}
        </div>

        <section>
            <div className={s.split}>
              <div className={s.splitSize}>
                <img className={s.heroImg} src={variant.files[1].preview_url} alt="Preview of the product" />
              </div>
              <div className={s.splitSize}>
                
                <div className="h3">
                  Price: {variant.retail_price} $
                </div>

                <p>
                  {product.product_description ? RichText.asText(product.product_description, linkResolver) : ""}
                </p>

                <p dangerouslySetInnerHTML={{__html: marked(variant.product.description)}} />

                <h5>Variations</h5>
  
                <RenderVariations variant={variant} variants={variants} setVariant={setVariant} />
                
                <div className="mt-3">
                  <button className="btn btn-outline-primary" onClick={() => addItem({
                    name: `${variant.name}`,
                    description: `#${variant.id} ${variant.variant.size} - ${variant.variant.color}`,
                    images: [variant.files[1].preview_url],
                    amount: parseFloat(variant.retail_price) * 100,
                    currency: variant.currency,
                    quantity: 1,
                  })}>Add to cart</button>
                </div>
              </div>
            </div>
        </section>
  
      </div>
  
    </React.Fragment>
  )
}

const Product = props => {
  console.log("PROPS", props)
  const doc = props.data.prismic.product;
  const printful = props.pageContext.printful
  if(!doc) return null

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{RichText.asText(doc.product_name)}</title>

        {printful.sync_variants.map((variant, index) => {
          return <link key={index} rel="preload" href={variant.files[1].preview_url} as="image" type="image/png"></link>
        })}
        
        
      </Helmet>
      <RenderBody product={doc} variants={printful.sync_variants} defaultVariant={printful.sync_variants[0]} />
    </Layout>
  )
}

export default Product